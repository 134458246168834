import React from 'react'
import styled from 'styled-components'
import { Link } from '../../../../@gatsbystorefront/.gatsby-theme-storefront-shopify/utils/general'
const StyledDiv = styled.div`
width: 100%;
background:transparent;
display:flex;
justify-content:center;
align-items:center;
flex-direction: row;

@media (max-width: 769px){
    flex-direction: column;
}
.image-div{
    max-width:100%;
    max-height:100%;
    background:white;
    display: flex;
    justify-content:center;
    align-items:center;
    flex: 2;
}
.work-text{
    background:green;
    display: flex;
    justify-content:center;
    align-items:center;
    flex: 1;
    align-self: center;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    font-family: "Harmonia Sans", 'Nunito Sans', Arial, sans-serif;
    line-height: 1.6;
    color: #002500;
    background-color: #FFFCF6;
    padding: 30px 30px 20px 20px;

}
.image-class{
    width: 100%;
    max-width:100%;
  }
.text-lime{
    margin-bottom: 25px;
    font-weight: 700;
    font-family: "Harmonia Sans", 'Nunito Sans', Arial, sans-serif;
    line-height: 1.2;
    text-align: left;
    color: #9ACA38;

}
.h2-class{
    margin-bottom: 25px;
    font-weight: 700;
    font-family: "Harmonia Sans", 'Nunito Sans', Arial, sans-serif;
    line-height: 1.2;
}
.p-class {
    margin-bottom: 15px;
    text-align: left;
    font-weight: 400;
    font-family: "Harmonia Sans", 'Nunito Sans', Arial, sans-serif;
    line-height: 2.0;
}
.orderNowButton{
    background: #dd0133;
    border: none !important;
    line-height: 1.42;
    text-decoration: none;
    text-align: center;
    white-space: normal;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    display: inline-block;
    padding: 11px 20px;
    width: auto;
    min-width: 90px;
    cursor: pointer;
    overflow: hidden;
    transition: all 1s ease;
    &:hover{
     background: #fe1348;
    }
  }
  

  .orderSpan {
    color: #ffffff;
    line-height: 1.42;
    text-align: center;
    white-space: normal;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

  @media (max-width: 769px){
    .orderSpan, .orderNowButton{
      font-size: 12px;
    }
  }
`

const WorkSection = () =>{
    return(
        <StyledDiv  className="page-width">
            <div className="image-div"> 
               <img className="image-class" src="//cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon_-_Open_Box_-_What_s_inside_30d94650-cc9d-4f13-9ad5-d9ebe997c5ae_1600x.png?v=1600302123" alt="" />
            </div>
            <div className="work-text">
            <div class="rte">
                <h2 className="h2-class">
                    How Does ProLon  <span class="text-lime">Work?</span>
                </h2>
                <p className="p-class">
                    ProLon is unlike any other diet program. The tasty, specially designed food gives you essential nutrition but doesn’t activate your body’s food sensing system. In other words, you get to eat, but your body “thinks” it’s on a 5-day fast. 
                </p>
                <p className="p-class">
                    Essentially, it’s a “fast with food.” If that sounds easier than fasting by starvation, that’s because it is.
                </p>
                <p className="p-class">
                    What makes ProLon so special is, simply put, the science. It’s based on over 20 years of scientific research sponsored by the National Institutes of Health and the USC Longevity Institute.
                </p>                    
                        <Link to="/products/buy-prolon" class="orderNowButton">
                            <span class="orderSpan">Order Now + Free Shipping</span>
                        </Link>              
                </div>


            </div>
        </StyledDiv>
    )
}

export default WorkSection